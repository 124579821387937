<template>
  <div>
    <div class="box">
      <div>
        <form @submit.prevent="onSubmit"></form>
        <div class="columns">
          <div class="column is-one-quarter">
            <b-field label="Sucursal">
              <b-select
                  v-model="branchoffice"
                  :disabled="true"
              >
                <option
                    v-for="(item, key) in branchOffices"
                    :key="key"
                    :value="item.id">{{item.name}}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <b-field label="">
              <b-radio v-model="type" native-value="1">Orden</b-radio>
              <b-radio v-model="type" native-value="2">Entrega</b-radio>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <b-field label="Fecha">
              <b-input v-model="date" type="date"/>
            </b-field>
          </div>
          <div class="column is-1">
            <p class="control">
              <button class="button has-icon is-primary" type="button" @click="onSubmit">
                <b-icon icon="search"></b-icon>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal">
      <iframe :src="url" frameborder="0" class="lapi-iframe"/>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PendingOrder",
  computed: {
    url() {
      return `${process.env.VUE_APP_API_URL}/reports/pending-orders/${this.branchoffice}/${this.type}/${this.dateFormatted}` ;
    },
    dateFormatted(){
      return this.$options.filters.date(this.date, 'YYYYMMDD');
    },
    ...mapState({
      branchOffices: state => state.main.branchOffices
    }),
  },
  data() {
    return {
      showModal: false,
      date: this.$options.filters.date(new Date(), 'YYYY-MM-DD'),
      type: 2,
      branchoffice: this.$auth.user().active_branch_office
    }
  },
  methods: {
    onSubmit(){
      this.showModal = true;
    }
  }
}
</script>

<style scoped>
.lapi-iframe{
  width: 100%;
  height: 100vh;
}
</style>
